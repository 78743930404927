
	import { Component, Provide, Vue } from 'vue-property-decorator';
	import { LIBRARY_TAB, LibraryFilePlans, LibraryFileType, LibraryQueryTypes, SEARCH_ROUTES } from '@/models/store/pixcapassets.interface';
	import { transformSearchWord } from '@/utils/StringUtils';
	import { beforeRouteEnter } from '@/utils/route';

	@Component({
		name: 'Library',
		beforeRouteEnter(to, from, next) {
			beforeRouteEnter(to, from, next);
		},
	})
	export default class Library extends Vue {
		@Provide()
		handleChangeFilter(payload: { tab?: string; search?: string; isPro?: boolean; isAnimated?: boolean; pageQuery?: number }) {
			const { tab = SEARCH_ROUTES.PACK_ALL_ASSET, search, isPro, isAnimated, pageQuery = 0 } = payload;
			const query = {};
			if (payload.hasOwnProperty('isAnimated') && isAnimated != null) {
				query[LibraryQueryTypes.TYPE] = isAnimated ? LibraryFileType.ANIMATED : LibraryFileType.STATIC;
			}
			if (payload.hasOwnProperty('isPro') && isPro != null) {
				query[LibraryQueryTypes.SHOW_ONLY_PRO] = isPro ? LibraryFilePlans.PRO : LibraryFilePlans.FREE;
			}
			if (payload.hasOwnProperty('pageQuery') && pageQuery != 0) {
				query[LibraryQueryTypes.PAGE] = pageQuery;
			} else {
				delete query[LibraryQueryTypes.PAGE];
			}

			const searchQuery = this.$route.params[LibraryQueryTypes.SEARCH_QUERY] || (search && transformSearchWord(search));
			if (searchQuery) {
				this.$router.push({
					name: 'LibraryExploreSearchList',
					params: { tab, [LibraryQueryTypes.SEARCH_QUERY]: searchQuery },
					query,
				});
			} else {
				this.$router.push({
					name: 'LibraryPage',
					params: { tab: LIBRARY_TAB.THREE_D_PACKS },
					query,
				});
			}
		}

		get isInDetailsRoute() {
			return ['LibraryItemDetails', 'LibraryPackDetailsPage', 'LibraryBrandKitPackDetailsPage', 'LibraryMockupDetailPage'].includes(this.$route.name);
		}
	}
